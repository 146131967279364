
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import Item from '@/modules/common/interfaces/item.interface';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import InsightFiltersService, { InsightFiltersServiceS } from '@/modules/document-filters/insight-filters.service';
import InsightsService, { InsightsServiceS } from '../insights.service';
import InsightTypeModel from '../models/insight-type.model';

@Component({
    components: {
        CustomMultiSelect,
    },
})
export default class InsightsFilters extends Vue {
    @Inject(InsightsServiceS) private insightsService!: InsightsService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(InsightFiltersServiceS) private insightFiltersService!: InsightFiltersService;

    get insightsTypesItems() {
        return this.insightFiltersService.options.insightTypes
            .filter(item => item.isActive);
    }

    get insightsTypes() {
        return this.insightFiltersService.settings.insightTypes || [];
    }

    set insightsTypes(value: InsightTypeModel[]) {
        this.insightFiltersService.updateSettings({ insightTypes: value });
    }

    get providerItems() {
        return this.insightsService.providerItems;
    }

    get providers() {
        return this.insightsService.settings.providers;
    }

    set providers(value: Item<string>[]) {
        this.insightsService.setSettingValue('providers', value);
    }

    get isLoading() {
        return this.insightFiltersService.loading.insightTypes.isLoading() || this.compsetsService.isLoading;
    }
}
